const config = {
    api: {
        url: 'https://api.rafflu.com/api/v1',
    },
    auth: {
        facebook: {
            appId: '625561391804789',
        },
        google: {
            clientId: '716788495755-v9re9vovq20lak4phjdirqpdviokr5na.apps.googleusercontent.com',
        },
    },
    pixabay: {
        apiKey: '21661028-ffa5d3aee7762d4123b4f3a73',
    },
} as const;

export type EnvConfig = typeof config;

export default config;
